import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { createInfo, deleteInfo, fetchInfo } from '../../../functions/firebasedb'

const Aptitudes = ({ keyArea }) => {

    const [aptitudes, setAptitudes] = useState({})
    const [title, setTitle] = useState("")

    const handleSubmit = e => createInfo( e, keyArea, title, setTitle, "aptitudes" );
    const handleDelete = ( id ) => deleteInfo(keyArea, id, "aptitudes")

    useEffect(() => {
        fetchInfo( keyArea, setAptitudes, "aptitudes" )
        return () => {}
    }, [])

    return (
        <div>
            <form onSubmit={ handleSubmit }>
                <div className="input-group">
                    <input 
                        type="text" 
                        className="form-control" 
                        id="titleAptitud" 
                        placeholder="Type title here..."
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        />
                    <div className="input-group-append">
                        <button type="submit" className="btn btn-warning">Add Aptitud</button>
                    </div>
                </div>
            </form>
            <table className="table table-striped mb-3">
                <thead>
                    <tr>
                        <th>Aptitud</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                {
                    aptitudes && Object.entries(aptitudes).map(([id, values]) => (
                        <tr key={id}>
                            <td>{ values.title }</td>
                            <td>
                                <button 
                                    className="btn btn-sm btn-outline-danger"
                                    onClick={ e => handleDelete(id)}
                                >Delete</button>    
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    )
}
export default Aptitudes;

Aptitudes.propTypes = {
    keyArea: PropTypes.string
}