import React, { useState, useEffect } from 'react'

export const Question = ({ question, id, counter, progress, setProgress }) => {
    const [temp, setTemp] = useState("")
    const setStatus = (value) => {
        if(temp !=value){
            counter(question.type, value, question.area);
            setTemp(value);
            if(temp == ""){
                setProgress(progress+1);
            }
        }
    }

    return (
        <div className={`question ${temp!="" ? "done" : ""}`}>
            <div className="text-content">
                <p> 
                    <strong>{question.position}</strong> 
                    {question.texto}
                </p>
            </div>
            <div className="text-action">
                <input type="radio" name={id} onClick={e => setStatus("add")} className="checkbox"/>
                <input type="radio" name={id} onClick={e => setStatus("rest")} className="checkbox"/>
            </div>
        </div>
    )
}
