import React from 'react'
import { useParams, Link } from 'react-router-dom';

import EditArea from './Fragments/EditArea'
import Interests from './Fragments/Interests'
import Aptitudes from './Fragments/Aptitudes'

const AreaDetails = () => {
    const { key } = useParams();
    console.log("key => ", key);
    return (
        <div className="page">
            <div className="p-2 mb-2">
                <Link to={`/areas/`}>
                    <button className="btn btn-outline-info">Atrás</button>
                </Link>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <EditArea keyArea={key}/>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <Interests keyArea={key}/>
                </div>
            </div>
            <div className="card mb-2">
                <div className="card-body">
                    <Aptitudes keyArea={key}/>
                </div>
            </div>
        </div>
    )
}
export default AreaDetails;