import firebase from '../constants/firebase'

const dbRef = firebase.database();

export const proccessStart = ( e, email, history ) => {
    e.preventDefault();
    if(email){
        let address = email.replace("@","-").replaceAll(" ", "").replaceAll(",","-").replaceAll(".","-");
        dbRef.ref(`/users/${address}/`).once('value', snap => {
            if(snap.val()){
                if(snap.val().result){
                    console.log("result >>", snap.val().result)
                    history.push(`/results/${address}/`);
                }else{
                    dbRef.ref(`/users/${address}/`).set({
                        active: "true",
                        email: email
                    });
                    history.push('/test/')
                }
            }else{
                dbRef.ref(`/users/${address}/`).set({
                    active: "true",
                    email: email
                });
                history.push(`/test/`)
            }
        })
        console.log(address)
    }else{
        alert('Ingresa un email válido');
    }
}