import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from '../views/Home';
import Admin from '../views/Admin';
import { Layout } from '../layouts/Layout';
import Areas from '../views/Areas';
import Questions from '../views/Questions';
import NewQuestion from '../views/NewQuestion';
import NewArea from '../views/NewArea';
import AreaDetails from '../views/AreaDetails';
import Test from '../views/Test';
import Results from '../views/Results';

const Navigation = () => {

    return (
        <BrowserRouter>
            <Layout>
                <Switch>
                    <Route path="/" exact component={Home}/>
                    <Route path="/admin" exact component={Admin}/>
                    <Route path="/areas" exact component={Areas}/>
                    <Route path="/areas/create" exact component={NewArea}/>
                    <Route path="/questions" exact component={Questions}/>
                    <Route path="/questions/create" exact component={NewQuestion}/>
                    <Route path="/areas/:key/view" exact component={AreaDetails}/>
                    <Route path="/test/" exact component={Test}/>
                    <Route path="/results/:address/" exact component={Results}/>
                </Switch>
            </Layout>
        </BrowserRouter>
    )
}

export default Navigation;