import logo from './logo.svg';
import './App.css';
import Navigation from './components/navigation/Navigation';


function App() {
  return (
    <Navigation/>
  );
}

export default App;
