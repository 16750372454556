import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { fetchAreas } from '../../functions/firebasedb';

const Areas = () => {

    const [areas, setAreas] = useState({})

    useEffect(() => {
        fetchAreas(setAreas);
        return () => {
            
        }
    }, [])

    return (
        <div className="page">
            <div className="p-2 mb-2">
                <Link to="/admin">
                    <button className="btn btn-outline-info">Página principal</button>
                </Link>
            </div>
            <div className="card">
                <div className="card-body">

                    <div className="row">
                        <div className="col-md-6">
                            <h5 className="card-title">Areas</h5>
                        </div>
                        <div className="col-md-6 text-right">
                            <Link to="/areas/create">
                                <button className="btn btn-primary btn-sm">Add Area</button>
                            </Link>
                        </div>
                    </div>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Key</th>
                                <th>Titulo</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {areas && Object.entries(areas).map(([id, area]) => {
                                return (
                                    <tr key={id}>
                                        <td>{area.key}</td>
                                        <td>{area.title}</td>
                                        <td>
                                            <Link to={`/areas/${area.key}/view`}>
                                                <button className="btn btn-sm btn-outline-info mr-2">View</button>
                                            </Link>
                                            <button className="btn btn-sm btn-outline-danger">Delete</button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}
export default Areas;