import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { fetchQuestions } from '../../functions/firebasedb';

const Questions = () => {

    const [questions, setQuestions] = useState({})

    useEffect(() => {
        fetchQuestions( setQuestions );
        return () => {}
    }, [])

    return (
        <div className="page">
            <div className="p-2 mb-2">
                <Link to="/admin">
                    <button className="btn btn-outline-info">Página principal</button>
                </Link>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                            <h5 className="card-title"><strong>Questions</strong></h5>
                        </div>
                        <div className="col-md-6 text-right">
                            <Link to="/questions/create">
                                <button className="btn btn-primary btn-sm">Add question</button>
                            </Link>
                        </div>
                    </div>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Pos</th>
                                <th>Area</th>
                                <th>Type</th>
                                <th>Texto</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            { questions && Object.entries(questions).map(([id, values]) => (
                                <tr key={id}>
                                    <td>{values.position}</td>
                                    <td>{values.area}</td>
                                    <td>{values.type}</td>
                                    <td>{values.texto}</td>
                                    <td>
                                        <button className="btn btn-sm btn-outline-danger">Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}
export default Questions;