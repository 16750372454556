import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { createInfo, deleteInfo, fetchInfo } from '../../../functions/firebasedb'

const Interests = ({ keyArea }) => {

    const [interests, setInterests] = useState({})
    const [title, setTitle] = useState("")

    const handleSubmit = (e) => createInfo( e, keyArea, title, setTitle, "interests" );
    const handleDelete = (id) => deleteInfo(keyArea, id, "interests");

    useEffect(() => {
        fetchInfo( keyArea, setInterests, "interests" );
        return () => {}
    }, [])

    return (
        <div>
            <form onSubmit={e => handleSubmit(e)}>
                <div className="input-group">
                    <input 
                        type="text" 
                        className="form-control" 
                        id="titleInterest" 
                        placeholder="Type title here..."
                        onChange={ e =>  setTitle(e.target.value)}
                        value={title} 
                        />
                    <div className="input-group-append">
                        <button type="submit" className="btn btn-success">Add Interest</button>
                    </div>
                </div>
            </form>
            <table className="table table-striped mb-3">
                <thead>
                    <tr>
                        <th>Interests</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        interests && Object.entries(interests).map(([id, values]) => (
                            <tr key={id}>
                                <td>{ values.title }</td>
                                <td>
                                    <button 
                                        className="btn btn-sm btn-outline-danger"
                                        onClick={ e => handleDelete(id) }
                                        >
                                        Delete
                                    </button>    
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}
export default Interests;

Interests.propTypes = {
    keyArea: PropTypes.string
}