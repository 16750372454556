import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { proccessStart } from '../../../functions/access';

export const StartScreen = () => {

    const [email, setEmail] = useState("")
    const history = useHistory();
    const handleSubmit = e => proccessStart(e, email, history );

    const [checked, setChecked] = useState(true)

    return (
        <div>
            <form className="mt-3" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="email" className="mb-2">Ingresa tu correo electrónico</label>
                    <input
                        type="email"
                        className="form-control mb-2 email validate"
                        placeholder="Correo electrónico"
                        onChange={e => setEmail(e.target.value)}
                    />
                    <input type="checkbox" name="policy" id="policy" checked={checked} onChange={e => setChecked(e.target.checked)}/>
                    <label htmlFor="policy" style={{fontSize: "13px", marginLeft:"10px"}}>Estoy de acuerdo en recibir información de UNIVES</label>
                    <br/><br />

                    <button className={`btn-success btn ${!checked ? "disabled" :""}`}>Empezar</button>
                </div>
            </form>
        </div>
    )
}
