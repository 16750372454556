import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { createQuestion, fetchAreas } from '../../functions/firebasedb';

const NewQuestion = () => {

    const [areas, setAreas] = useState({})
    const [texto, setTexto] = useState("")
    const [area, setArea] = useState("admin")
    const [type, setType] = useState("interests")
    const [position, setPosition] = useState(1)

    const handleSubmit = (e) => createQuestion(e, texto, area, type, position, setTexto );

    useEffect(() => {
        fetchAreas( setAreas );
        return () => {}
    }, [])

    return (
        <div className="page">
            <div className="p-2 mb-2">
                <Link to="/questions">
                    <button className="btn btn-outline-info">Atrás</button>
                </Link>
            </div>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title"><strong>New Question</strong></h5>
                    <form onSubmit={ handleSubmit }>
                        <div className="form-group">
                            <label htmlFor="texto">Texto</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="texto"
                                value={ texto }
                                onChange={ e => setTexto(e.target.value)}
                                />
                        </div>
                        <div className="form-group">
                            <label htmlFor="area">Área</label>
                            <select 
                                name="area" 
                                id="area" 
                                className="form-control"
                                value={ area }
                                onChange={e => setArea(e.target.value)}
                                >
                                {
                                    areas && Object.entries(areas).map(([id, values]) => (
                                        <option key={id} value={`${id}`}>{values.title}</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="type">Type</label>
                            <select 
                                name="type" 
                                id="type" 
                                className="form-control"
                                value={ type}
                                onChange={ e => setType( e.target.value )}
                                >
                                <option value="interests">Interest</option>
                                <option value="aptitudes">Aptitude</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="position">Position</label>
                            <input 
                                type="number" 
                                className="form-control" 
                                id="position"
                                value={position}
                                onChange={e => setPosition(e.target.value)}
                                />
                        </div>
                        <div className="text-right">
                            <button type="submit" className="btn btn-primary">Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default NewQuestion;