import React, { useState } from 'react'
import { useParams } from 'react-router-dom';

const Results = () => {

    const { address } = useParams();
    const [user, setUser] = useState({})
    const [areaInt, setAreaInt] = useState({})
    const [areaApt, setAreaApt] = useState({})

    // useEffect(() => {
    //     fetchUserResults( address, user, setUser, setAreaInt, setAreaApt );

    //     return () => {}
    // }, [])
    return (
        <div>
            <div className="row">
                    <div className="col-sm-12 col-md-6" style={{display: areaInt.title ? "block" : "none"}}>
                        <table className="table table-striped text-center">
                            <thead>
                                <tr>
                                    <th>Intereses</th>
                                </tr>
                                <tr>
                                    <th>
                                        <button className="btn btn-info">Área: { areaInt && areaInt.title }</button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                { areaInt.title && Object.entries(areaInt.interests).map((interest) => {
                                    console.log(interest[1].title);
                                    return(
                                        <tr>
                                            <td>{interest[1].title}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-sm-12 col-md-6" style={{display: areaApt.title ? "block" : "none"}}>
                        <table className="table table-striped text-center">
                            <thead>
                                <tr>
                                    <th>Aptitudes</th>
                                </tr>
                                <tr>
                                    <th>
                                        <button className="btn btn-info">Área: { areaApt && areaApt.title }</button>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                { areaApt.title && Object.entries(areaApt.aptitudes).map((aptitudes) => {
                                    console.log(aptitudes[1].title);
                                    return(
                                        <tr>
                                            <td>{aptitudes[1].title}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
        </div>
    )
}
export default Results;