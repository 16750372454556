import React, { useState } from 'react'
import { handleLogin } from '../../functions/access'
import { GetResultScreen } from './Fragments/GetResultScreen'
import { StartScreen } from './Fragments/StartScreen'

const Home = () => {


    return (
        <div className="ctx">
            <h5 className="start-header">Realizar Test Vocacional </h5>
            <div className="card card-start" style={{ maxWidth: "600px", margin: "auto" }}>
                <div className="card-body text-center">
                    <StartScreen />
                </div>
            </div>
            <div className="text-home">
                <p>
                    <strong>Instrucciones</strong> <br />
                    <hr />
                Este cuestionario está compuesto por 98 enunciados. No existe limitación de tiempo. La mayoría de la gente tarda unos 20 minutos. Por favor, tómese el tiempo necesario.
                Responda a todos la más exacta. <br /><br />
                </p><p>
                Las ventajas de desarrollar de esta forma el Test CHASIDE son que:
                    <ul>
                        <li>Permite un rápido cálculo del área de interés.</li>
                        <li>Brinda un formato de impresión adecuado para realizar un reporte.</li>
                    </ul>

                </p>

            </div>
            <br /><br />
            
        </div>
    )
}

export default Home;