import firebase from '../constants/firebase';

const dbRef = firebase.database();

export const createArea = (e, title, key, setTitle, setKey) => {
    e.preventDefault();
    if(title && key){
        try{
            dbRef.ref(`areas/${key}`).set({
                title, key
            })
            setTitle("");
            setKey("");
            console.log("Works")
        }catch(err){
            console.log(err)
        }
    }else{
        alert("Rellena los campos")
    }
}

export const updateArea = ( e, areaKey, title, key, setMessage ) => {
    e.preventDefault();
    if(title && key){
        try{
            dbRef.ref(`areas/${areaKey}/`).update({
                title, key
            });
            setMessage("Actualizado correctamente");
            setTimeout(() => {
                setMessage("")
            },2000)
        }catch(err){
            console.log(err)
        }
    }else{
        alert("Rellena los campos")
    }
}

export const fetchAreas = ( setAreas) => {
    dbRef.ref('/areas/').on('value', snap => {
        if(snap.val()){
            setAreas(snap.val());
        }
    })
}

export const fetchArea = ( key, setArea, history ) => {
    dbRef.ref(`areas/${key}/`).once('value', snap => {
        if(snap.val()){
            setArea(snap.val())
        }else{
            history.push('/404');
        }
    })
}

export const createInfo = ( e, areaKey, title, setTitle, type ) => {
    e.preventDefault();
    if(title){
        try{
            dbRef.ref(`/areas/${areaKey}/${type}/`).push({
                title
            });
            setTitle("")
        }catch(err){
            console.log(err)
        }
    }else{
        alert("Rellena el campo");
    }
}

export const fetchInfo = (areaKey, setInfo, type) => {
    dbRef.ref(`areas/${areaKey}/${type}/`).on('value', snap => {
        if(snap.val()){
            setInfo(snap.val())
        }
    })
}

export const deleteInfo = ( areaKey, id, type ) => {
    try{
        dbRef.ref(`areas/${areaKey}/${type}/${id}/`).remove();
    }catch(err){
        console.log(err)
    }
}

// Questions

export const createQuestion = ( e, texto, area, type, position, setTexto ) => {
    e.preventDefault();
    if(texto && area && type && position){
        try{
            dbRef.ref('questions').push({
                texto, area, type, position
            });
            setTexto("");
        }catch(err){
            console.log(err)
        }
    }
}

export const fetchQuestions = ( setQuestions ) => {
    dbRef.ref('questions').on('value', snap => {
        if(snap.val()){
            setQuestions(snap.val())
        }
    })
}

// Fetch results

export const fetchData = ( area, areaType, setData ) => {
    dbRef.ref(`/areas/${area}/`).on('value', snap => {
        if(snap.val()){
            setData(snap.val());
        }
    })
}

