import React from 'react'
import { Link } from 'react-router-dom';

const Admin = () => {
    return (
        <div className="p-2 page">
            <Link to="/questions">
                <button className="btn btn-success mr-2">Preguntas</button>
            </Link>
            <Link to="/areas">
                <button className="btn btn-info mr-2">Áreas</button>
            </Link>
            <Link to="/">
                <button className="btn btn-warning ml-2">Home</button>
            </Link>
        </div>
    )
}

export default Admin;