import React from 'react';
import { Link } from 'react-router-dom';

export const Navbar = () => {
    return (
        <div className="header-wrapper mb-3" id="header-wrapper">
            <div className="header-content">
                    <img src="https://unives.mx/wp-content/uploads/2021/05/UNIVES27.png" className="logo"/>
                {/* <Link to="/">
                </Link> */}
            </div>
        </div>
    )
}
