import firebase from 'firebase';

const Firebase = firebase.initializeApp({
    apiKey: "AIzaSyDipKgH-V9UXzrVYjAftqE6ETb3IGQMhPA",
    authDomain: "testvocacional-96951.firebaseapp.com",
    projectId: "testvocacional-96951",
    storageBucket: "testvocacional-96951.appspot.com",
    messagingSenderId: "305018947467",
    appId: "1:305018947467:web:b8d48aa0bae134c801118e",
    measurementId: "G-HX2QTH9FW0"
  });

  export default firebase;