import React, { useState } from 'react'
import { createArea } from '../../functions/firebasedb'
import { Link } from 'react-router-dom';

const NewArea = () => {

    const [title, setTitle] = useState("")
    const [key, setKey] = useState("")

    const handleSubmit = e => createArea(e, title, key, setTitle, setKey);

    return (
        <div className="page">
            <div className="p-2 mb-2">
                <Link to="/areas">
                    <button className="btn btn-outline-info">Areas</button>
                </Link>
            </div>
            <div className="card">
                <div className="card-body">
                    <form onSubmit={ handleSubmit }>
                        <div className="form-group">
                            <label htmlFor="title">Title</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="title"
                                onChange={ e => setTitle(e.target.value)}
                                value={title}
                                 />
                        </div>
                        <div className="form-group">
                            <label htmlFor="key">Key</label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="key"
                                placeholder="admin" onChange={e => setKey(e.target.value)}
                                value={key}
                                 />
                        </div>
                        <div>
                            <button type="submit" className="btn btn-primary">Guardar</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    )
}
export default NewArea;