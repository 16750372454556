import React, { useEffect, useState } from 'react'
import { fetchData, fetchQuestions } from '../../functions/firebasedb'
import { Question } from './Fragments/Question'
import audio from '../../assets/success.m4a';
import { Accordion } from './Fragments/Info';


const Test = () => {

    const [questions, setQuestions] = useState({})

    const [progress, setProgress] = useState(0)
    const [size, setSize] = useState(100);

    const [areaApt, setAreaApt] = useState({})
    const [areaInt, setAreaInt] = useState({})

    const [admin, setAdmin] = useState({ interests: 0, aptitudes: 0 })
    const [art, setArt] = useState({ interests: 0, aptitudes: 0 })
    const [exact, setExact] = useState({ interests: 0, aptitudes: 0 })
    const [human, setHuman] = useState({ interests: 0, aptitudes: 0 })
    const [ing, setIng] = useState({ interests: 0, aptitudes: 0 })
    const [salud, setSalud] = useState({ interests: 0, aptitudes: 0 })
    const [seg, setSeg] = useState({ interests: 0, aptitudes: 0 })

    const counter = (type, value, area) => {
        switch (area) {
            case "admin":
                type == "interests"
                    ? setAdmin(prevAdmin => { return { ...prevAdmin, interests: run(value, prevAdmin.interests) } })
                    : setAdmin(prevAdmin => { return { ...prevAdmin, aptitudes: run(value, prevAdmin.aptitudes) } })
                break;
            case "art":
                type == "interests"
                    ? setArt(prevArt => { return { ...prevArt, interests: run(value, prevArt.interests) } })
                    : setArt(prevArt => { return { ...prevArt, aptitudes: run(value, prevArt.aptitudes) } })
                break;
            case "exact":
                type == "interests"
                    ? setExact(prevExact => { return { ...prevExact, interests: run(value, prevExact.interests) } })
                    : setExact(prevExact => { return { ...prevExact, aptitudes: run(value, prevExact.aptitudes) } })
                break;
            case "human":
                type == "interests"
                    ? setHuman(prevHuman => { return { ...prevHuman, interests: run(value, prevHuman.interests) } })
                    : setHuman(prevHuman => { return { ...prevHuman, aptitudes: run(value, prevHuman.aptitudes) } })
                break;
            case "ing":
                type == "interests"
                    ? setIng(prevIng => { return { ...prevIng, interests: run(value, prevIng.interests) } })
                    : setIng(prevIng => { return { ...prevIng, aptitudes: run(value, prevIng.aptitudes) } })
                break;
            case "salud":
                type == "interests"
                    ? setSalud(prevSalud => { return { ...prevSalud, interests: run(value, prevSalud.interests) } })
                    : setSalud(prevSalud => { return { ...prevSalud, aptitudes: run(value, prevSalud.aptitudes) } })
                break;
            case "seg":
                type == "interests"
                    ? setSeg(prevSeg => { return { ...prevSeg, interests: run(value, prevSeg.interests) } })
                    : setSeg(prevSeg => { return { ...prevSeg, aptitudes: run(value, prevSeg.aptitudes) } })
                break;
        }

    }

    const run = (type, current) => {
        if (type == "add") {
            return current + 1;
        } else {
            return current > 0 ? current - 1 : current;
        }
    }

    const getResult = () => {
        const _items = [
            { name: "admin", interests: admin.interests, aptitudes: admin.aptitudes },
            { name: "art", interests: art.interests, aptitudes: art.aptitudes },
            { name: "exact", interests: exact.interests, aptitudes: exact.aptitudes },
            { name: "human", interests: human.interests, aptitudes: human.aptitudes },
            { name: "ing", interests: ing.interests, aptitudes: ing.aptitudes },
            { name: "salud", interests: salud.interests, aptitudes: salud.aptitudes },
            { name: "seg", interests: seg.interests, aptitudes: seg.aptitudes }
        ];
        let r_interests = [..._items].sort((a, b) => {

            if (a.interests < b.interests) return 1;
            if (a.interests > b.interests) return -1;
            return 0;
        });
        if (r_interests[0].name) {
            fetchResults(r_interests[0].name, "interests", setAreaInt);
        }
        let r_aptitudes = [..._items].sort((a, b) => {
            if (a.aptitudes < b.aptitudes) return 1;
            if (a.aptitudes > b.aptitudes) return -1;
            return 0;
        });
        if (r_aptitudes[0].name) {
            fetchResults(r_aptitudes[0].name, "aptitudes", setAreaApt);
        }
    }

    const fetchResults = (area, areaType, setData) => fetchData(area, areaType, setData);

    useEffect(() => { fetchQuestions(setQuestions) }, [])
    useEffect(() => { if (questions) { setSize(Object.keys(questions).length); } }, [questions])
    useEffect(() => { if (progress > 0) { document.getElementById("audio").play(); } }, [progress])
    useEffect(() => {
        window.onscroll = () => {
            let _wrapper = document.getElementById("header-wrapper");
            let _progress = document.getElementById("progress");
            let _t_header = document.getElementById("t-header");
            if(window.pageYOffset > 30){
                if(_wrapper) _wrapper.classList.add("more")
                if(_progress) _progress.classList.add("more")
                if(_t_header) _t_header.classList.add("more")
            }else{
                if(_wrapper) _wrapper.classList.remove("more")
                if(_progress) _progress.classList.remove("more")
                if(_t_header) _t_header.classList.remove("more")
            }
        }
        return ()=>{
            window.removeEventListener("scroll", {});
        }
    },[])

    return (
        <div className="test-content">
            <div className="progress" id="progress">
                <div
                    className="progress-bar progress-bar-striped bg-success progress-bar-animated"
                    role="progressbar"
                    aria-valuenow={progress}
                    aria-valuemax="100"
                    style={{ width: `${(100 / size) * progress}%` }}
                ></div>
            </div>
            <div className="container">
                <div className="t-header" id="t-header">
                    <div className="t-text-content">
                        <p><strong>Pregunta</strong></p>
                    </div>
                    <div className="t-text-actions">
                        <span><strong>Sí</strong></span>
                        <span><strong>No</strong></span>
                    </div>
                </div>
                <div>
                    {questions && Object.entries(questions).map(([id, question]) => (
                        <Question key={id} id={id} question={question} counter={counter} progress={progress} setProgress={setProgress} />
                    ))}
                </div>
                <div className="mt-2">
                    <br />
                    <div className="text-center">
                        {/* <button className={`btn btn-success `} onClick={ getResult }>Calcular resultado</button> */}
                        <button
                            className={`btn btn-success ${((100 / size) * progress) < 100 ? "disabled" : ""}`}
                            onClick={getResult}
                        >Calcular resultado</button>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6" style={{ display: areaInt.title ? "block" : "none" }}>
                            <table className="table table-striped text-center">
                                <thead>
                                    <tr>
                                        <th>Intereses</th>
                                    </tr>
                                    <tr>
                                        <th>
                                            <button className="btn btn-info">Área: {areaInt && areaInt.title}</button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {areaInt.title && Object.entries(areaInt.interests).map((interest) => {
                                        console.log(interest[1].title);
                                        return (
                                            <tr>
                                                <td>{interest[1].title}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <br />
                            <br /><br />
                        </div>
                        <div className="col-sm-12 col-md-6" style={{ display: areaApt.title ? "block" : "none" }}>
                            <table className="table table-striped text-center">
                                <thead>
                                    <tr>
                                        <th>Aptitudes</th>
                                    </tr>
                                    <tr>
                                        <th>
                                            <button className="btn btn-info">Área: {areaApt && areaApt.title}</button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {areaApt.title && Object.entries(areaApt.aptitudes).map((aptitudes) => {
                                        console.log(aptitudes[1].title);
                                        return (
                                            <tr>
                                                <td>{aptitudes[1].title}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <br />
                        </div>
                    </div>
                    <div style={{ display: areaApt.title ? "block" : "none" }}>
                        <Accordion/>
                    </div>
                    <br /><br />
                </div>
                <audio src={audio} id="audio"></audio>
            </div>
        </div>

    )
}
export default Test;