import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { fetchArea, updateArea } from '../../../functions/firebasedb';
import PropTypes from 'prop-types'

const EditArea = ({ keyArea }) => {

    const history = useHistory();

    const [area, setArea] = useState({})
    const [title, setTitle] = useState("")
    const [key, setKey] = useState("")
    const [message, setMessage] = useState("")

    const handleSubmit = e => updateArea(e , keyArea, title, key, setMessage );

    useEffect(() => {
        fetchArea(keyArea, setArea, history);
        return () => {}
    }, [])
    
    useEffect(() => {
        if(area){
            setTitle(area.title);
            setKey(area.key);
        }
    },[area])
    return (
        <div>
            <form onSubmit={ handleSubmit }>
                <div className="form-group">
                    <label htmlFor="titleArea">Title</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="titleArea" 
                        onChange={e => setTitle(e.target.value)}
                        value={title}
                        />
                </div>
                <div className="form-group">
                    <label htmlFor="key">Key</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        id="key"
                        onChange={e => setKey(e.target.value)}
                        value={key}
                        />
                </div>
                { message && (
                    <div className="alert alert-dismissible alert-success">
                        { message }
                    </div>
                )}
                <div className="text-right">
                    <button type="submit" className="btn btn-outline-primary">Save changes</button>
                </div>
            </form>
        </div>
    )
}
export default EditArea;

EditArea.propTypes = {
    keyArea: PropTypes.string
}