import React from 'react'

export const InfoInterests = () => {
    return (
        <div>
            <p style={{lineHeight: 2}}> <strong className="sub_heading">Intereses</strong><hr />
                Los intereses son la preferencia hacia ciertas actividades; la inclinación y la motivación que te hace inclinarte a cierto tipo de acciones sin la necesidad de verse forzado los enunciados y por orden. <br />
                Asegúrese de que ha elegido la opción correcta. Si necesita cambiar una respuesta, simplemente seleccione la nueva respuesta y la incorrecta desaparecerá. <br />
                Descríbase a sí mismo tal y como es en general en la actualidad, no como desearía ser en el futuro. <br />
                Descríbase a sí mismo tal y como se ve en relación con otras personas del mismo sexo y, más o menos, de su misma edad. Una respuesta espontánea suele ser a realizarla, más bien, el poder realizar dicha actividad produce <strong>satisfacción y alegría</strong>. <br />
                Por ejemplo: intereses hacia la ayuda social, interés hacia los números, interés hacia las actividades artísticas, interés a trabajar al aire libre, etc. <br /><br />
                <strong>¿Cómo reconocer mis intereses? </strong> 
                Pregúntate, ¿en qué actividades yo ….? <br />
                <ul>
                    <li>Me siento motivado a realizar sin que nadie me lo indique.</li>
                    <li>Podría realizar muchas veces con la misma emoción.</li>
                    <li>Invierto mucho tiempo.</li>
                </ul>
            </p>

        </div>
    )
}

export const InfoAptitude = () => {
    return (
        <div>
            <p style={{lineHeight: 2}}>
                <strong className="sub_heading">Aptitudes</strong> <hr />
                Son aquellas actividades en las que te desempeñas bien, es la aptitud innata, talento, destreza o capacidad que posee una persona para llevar a cabo con éxito una determinada actividad, trabajo u oficio. <br />

                <strong></strong> Habilidades corporales (de coordinación); habilidades intrapersonales (facilidad para expresar ideas ante grupos); Habilidades musicales (facilidad para percibir tonos, ritmos, composición, etc); habilidad de observación (capacidad de percibir detalles, ver patrones de acciones); entre otras. <br />
                <strong>¿Cómo reconocer mis habilidades?</strong>
            Pregúntate, ¿en qué actividades yo ….? <br />
                <ul>
                    <li>Me siento con la capacidad de terminar exitosamente</li>
                    <li>Tengo facilidad de realizar.</li>
                    <li>Puede realizarlas por mucho tiempo y aún así hacerlo bien</li>
                </ul>
            </p>
        </div>
    )

}

export const Accordion = () => (
    <div id="accordion">
        <div className="">
            <div className="card-header" id="headingOne">
                <h5 className="mb-0">
                    <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        Más información sobre los resultados
                    </button>
                </h5>
            </div>

            <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <InfoInterests/>
                            <br />
                        </div>
                        <div className="col-sm-12">
                            <InfoAptitude/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)